<template>
<div class="image">
	<div class="image__overlay" :class="$store.request.flipped ? 'image__overlay--flipped' : ''" v-if="overlay">
		<img :src="overlay" />
	</div>
	<img :src="image" />
</div>
</template>

<script>
import Placeholder from '@/assets/placeholder.jpg';
export default {
	name: 'CapturedImage',
	props: {
		image: {
			type: String,
			default: Placeholder
		},
		overlay: {
			type: String,
			default: null
		}
	}
}
</script>

<style scoped lang="scss">
.image {
	width: 100%;
	padding-bottom: 75%;
	position: relative;
	background-color: #fbfbfb;

	img {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		object-fit: cover;
		object-position: center;
		height: 100%;
		width: 100%;
	}
}

.image__overlay {
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: -1px;
	left: 0;
	right: 0;
	img {
		width: 100%;
	}
}

.image__overlay--flipped {
	transform: scaleX(-1);
}
</style>
